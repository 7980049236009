import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout-page"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const MyParagraph = props => <Typography variant='body1' {...props} paragraph/>

const components = {
  p: MyParagraph,
}

const BlogPadding = styled.div`
   @media (min-width: 992px) {
      padding: 16px 128px;
   }
   @media (min-width: 768px) and (max-width: 991px) {
      padding: 16px 64px;
   }
   @media (min-width: 576px) and (max-width: 767px) {
      padding: 16px 32px;
   }
   @media (max-width: 566px) {
      padding: 16px 16px;
   }
`

const ButtonContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   padding: 0;
   margin: 32px 0px;
`

const BlogPostTemplate = ({ data, pageContext }) => {
   const post = data.mdx
   const { previous, next } = pageContext
   return (
      <Layout>
         <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
         <PageLayout title={post.frontmatter.title} blog={true}>
            <BlogPadding>
               <Typography display={'block'} variant={'subtitle2'} gutterBottom>
                  {post.frontmatter.date}
               </Typography>
               <MDXProvider components={components}>
                  <MDXRenderer>
                     {post.body}
                  </MDXRenderer>
               </MDXProvider>
               <ButtonContainer>
                  {previous && (
                     <Link to={`blog${previous.fields.slug}`} rel="prev" style={{textDecoration:'none'}}>
                        <Button size='large'> ← {previous.frontmatter.title} </Button>
                     </Link>
                  )}
                  {next && (
                     <Link to={`blog${next.fields.slug}`} rel="next" style={{textDecoration:'none'}}>
                        <Button size='large'> {next.frontmatter.title} → </Button>
                     </Link>
                  )}
               </ButtonContainer>
            </BlogPadding>
         </PageLayout>
      </Layout>
   )
}

export default BlogPostTemplate

export const pageQuery = graphql`
   query BlogPostBySlug($slug: String!) {
      site {
         siteMetadata {
         title
            author
         }
      }
      mdx(fields: { slug: { eq: $slug } }) {
         id
         excerpt(pruneLength: 160)
         body
         frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
         }
      }
   }
`
